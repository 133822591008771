<template>
  <div class="info">
    <van-cell-group>
      <van-cell>
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">咨询信息</span>
        </template>
      </van-cell>
      <van-form @submit="submit" input-align="right" error-message-align="right">
        <van-field label="咨询问题" clearable clear-trigger="always" v-model="questionTitle" required :rules="[{ required: true, message: '请输入咨询问题' }]" />
        <van-field clearable clear-trigger="always" :border="false" class="left" v-model="questionContent" rows="3"
          autosize type="textarea" placeholder="请输入问题描述" input-align="left" required :rules="[{ required: true, message: '请输入咨询描述' }]" />
      </van-form>
      
      <van-cell>
        <template #title>
          <div style="display:flex">
            <upload-file :file-list="fileList" del-btn-show upload-btn-show @filesUpload="upload" upload-path="files/commonApp/guide/faqs/temporary" />
          </div>
        </template>
      </van-cell>
    </van-cell-group>
    <div class="form-btn-view">
      <van-row class="btns">
        <van-col span="24">
          <van-button type="info" size="large" round @click="submit">提交</van-button>
        </van-col>
      </van-row>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import UploadFile from '@/components/upload/uploadFile'
export default {
  components: {
    UploadFile
  },
  data() {
    return {
      questionTitle: '',
      questionContent: '',
      fileList: [],
      newFileMessages: []
    }
  },
  computed: { ...mapState(['guideId']) },
  methods: {
    upload (files) {
      this.fileList = files
      this.newFileMessages = files.filter(item => !item.id)
    },
    submit () {
      this.$http({
        url: this.$http.adornUrl("/wxapp/commonApp/guide/faqs/save"),
        method: "post",
        data: this.$http.adornData({
          guideId: parseInt(this.guideId),
          questionTitle: this.questionTitle,
          questionContent: this.questionContent,
          newFileMessages: this.newFileMessages,
          createUserId: this.$userId,
          communityId: this.$orgId
        }),
      }).then(({ data }) => {
        if (data.code == 0) {
          this.$toast.success({
            message: '提交成功',
            duration: 3000,
            onOpened: () => {
              setTimeout(() => {
                this.$toast.clear()
                this.$router.go(-1)
              }, 1500)
            }
          })
        } else {
          this.$toast.fail(data.msg)
        }
      })
    }
  }
}
</script>